import Link from 'gatsby-plugin-transition-link'
const AniLink = Link
import React from 'react'

import '../styles/header.sass'

const English_Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                Source Theme by <a href={'https://umisc.info'}>UMISC</a> • <Link to='/en/privacy'>Privacy Policy</Link> • © Copyright PolyCyber
            </div>
        </footer>)
}

export default English_Footer
